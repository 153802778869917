import React, { Suspense } from 'react';
import DocumentTitle from 'react-document-title';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MenuBase } from './config';
import NotFound from '@/pages/notFound';
import Cookies from 'js-cookie';

// 垃圾代码，需要token 默认值
const token = Cookies.get('token') || '123';

const SuspenseComponent = (props: any, extraProps: any, route: MenuBase) => {
  return (
    <Suspense fallback={<></>}>
      <route.component {...props} {...extraProps} route={route} />
    </Suspense>
  );
};

function requestAuth(route: any, props: any, extraProps: any) {
  if (token || route.requiresNotAuth) {
    return route.render ? (
      route.render({ ...props, ...extraProps, route: route })
    ) : (
      <DocumentTitle title={route.name}>
        {route.layout ? (
          <route.layout route={route}>{SuspenseComponent(props, extraProps, route)}</route.layout>
        ) : (
          SuspenseComponent(props, extraProps, route)
        )}
      </DocumentTitle>
    );
  } else {
    return <Redirect to="/profile" />;
  }
}

function renderRoutes(routes: MenuBase[], extraProps = {}, switchProps = {}) {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route: MenuBase, i: number) => {
        return (
          <Route
            key={route.path || i}
            path={route.path}
            exact={route.exact}
            render={props => requestAuth(route, props, extraProps)}
          />
        );
      })}
      <Route key="/404" path="/404" exact component={NotFound} />
    </Switch>
  ) : null;
}

export default renderRoutes;
