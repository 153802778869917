import { constants } from './constants';
import { login, registryConsumer, currentUserInfo } from '@/api/apiServer/apiUser';
import { qrcodeSearch, pointSearch } from '@/api/apiServer/apiOther';
import { mallList, orderList } from '@/api/apiServer/apiProfile';
import { Toast } from 'antd-mobile';
import { removeParam } from '@/utils/index';

// token
export const userTokenAction = (payload: any) => ({
  type: constants.USER_TOKEN,
  payload
});

// 用户注册
export const userRegAction = (payload: any) => ({
  type: constants.REG_CONSUMER,
  payload
});

export const qrcodeAction = (payload: any) => ({
  type: constants.QRCODE_SEARCH,
  payload
});

export const pointAction = (payload: any) => ({
  type: constants.POINT_SEARCH,
  payload
});

/**
 * 用户登录
 *
 * @param {Object}
 *
 * @returns {Object}
 */
export const fetchLoginAction = (payload: any) => {
  return async (dispatch: any) => {
    let {
      data: { code, data, msg }
    } = await login(payload);
    console.log('login===', data);
    if (code === '00000000') {
      dispatch(userTokenAction(data));
    } else if (code === '00000001') {
      const url = removeParam('code', window.location.href);
      window.location.href = url ? url : window.location.href;
    } else {
      Toast.fail(msg);
    }
  };
};

/**
 * 用户注册
 *
 * @param {Object}
 *
 * @returns {Object}
 */
export const fetchRegistry = (payload: any) => {
  const { params, resolve, reject } = payload;
  return async (dispatch: any) => {
    let {
      data: { code, data, msg }
    } = await registryConsumer(params);
    console.log('registry===', data);
    if (code === '00000000') {
      dispatch(userRegAction(data));
      resolve(data);
    } else {
      Toast.fail(msg);
      reject(msg);
    }
  };
};

// currentUserInfo
export const fetchUserInfo = (payload: any) => {
  return async (dispatch: any) => {
    let {
      data: { code, data, msg }
    } = await currentUserInfo(payload);
    console.log('registry===', data);
    if (code === '00000000') {
      dispatch(userRegAction(data));
    } else {
      Toast.fail(msg);
    }
  };
};

export const fetchQrcodeAction = (payload: any) => {
  return async (dispatch: any) => {
    let {
      data: { code, data, msg }
    } = await qrcodeSearch(payload);
    console.log('qrcodeSearch===', data);
    if (code === '00000000') {
      dispatch(qrcodeAction(data));
    } else {
      Toast.fail(msg);
    }
  };
};

export const fetchPointAction = (payload: any) => {
  return async (dispatch: any) => {
    let {
      data: { code, data, msg }
    } = await pointSearch(payload);
    console.log('pointSearch===', data);
    if (code === '00000000') {
      dispatch(pointAction(data));
    } else {
      Toast.fail(msg);
    }
  };
};

export const mallListAction = (payload: any) => ({
  type: constants.MALL_LIST,
  payload
});

export const fetchMallListAction = (payload: any, resolve?: any, reject?: any) => {
  return async (dispatch: any) => {
    let { data } = await mallList(payload);
    if (data.code === '00000000') {
      dispatch(mallListAction(data));
      resolve && resolve(data);
    } else {
      reject && reject(data.msg);
      Toast.fail(data.msg);
    }
  };
};

export const orderListAction = (payload: any) => ({
  type: constants.ORDER_LIST,
  payload
});

export const fetchOrderListAction = (payload: any) => {
  return async (dispatch: any) => {
    let { data } = await orderList(payload);
    if (data.code === '00000000') {
      dispatch(orderListAction(data));
    } else {
      Toast.fail(data.msg);
    }
  };
};
