import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import Footer from '@/components/Footer';
import styles from './BasicLayout.module.less';
import { currentUserInfo } from '@/api/apiServer/apiUser';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PageLoading from '@/components/PageLoading';
import { userRegAction } from '@/store/user/actions';
import { Toast } from 'antd-mobile';

const footerBlack = [
  '/pointsMall',
  '/pointsMall/exchange',
  '/cashCoupons',
  '/cashCoupons/used',
  '/cashCoupons/invalid',
  '/cashCoupons/destroy',
  '/cashCoupons/exchange',
  '/order',
  '/turntable',
  '/turntable/record'
];

const grayBgRoutes = ['/withdraw/input'];

const BasicLayout = (props: any) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [isReady, setReady] = useState(false);
  const [finish, setFinish] = useState(false);
  const { openid } = useSelector((state: any) => state.userReducer);
  const getAccount = useCallback(() => {
    currentUserInfo({ openId: openid })
      .then(({ data }) => {
        if (data.code === '00000000') {
          // history.push('/');
          if (
            data.data.reviewStatus === 0 ||
            (data.data.reviewStatus === 1 && data.data.isPass === 0)
          ) {
            // 待审核 或者 审核不通过
            push('/registryResult');
          }
          dispatch(userRegAction(data.data));
          setFinish(true);
        } else if (data.code === '00000003') {
          push('/registry');
        } else {
          Toast.info(data.msg);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [push, openid, dispatch]);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    if (openid && isReady) {
      getAccount();
    }
  }, [getAccount, openid, isReady]);

  if (!(finish && isReady && openid)) {
    return <PageLoading />;
  }
  return (
    <div
      className={classnames(
        styles['basic-layout-wrap'],
        'flex',
        'flex-v',
        grayBgRoutes.includes(props.route.path) ? styles['basic-gray'] : null
      )}
    >
      <div style={{ width: '100%', height: '100%' }} className="flex flex-v flex-1">
        {props.children}
      </div>
      {!footerBlack.includes(props.route.path) && <Footer />}
    </div>
  );
};

export default BasicLayout;
