import layouts from '@/layouts/index';
import React, { lazy, LazyExoticComponent } from 'react';
import profileRoutes from './profile';
const { BasicLayout = '', RegistryLayout = '', FullLayout = '' } = { ...layouts };
export interface MenuBase {
  path: string;
  name: string;
  exact?: boolean;
  strict?: string;
  render?: any;
  query?: string;
  auth?: string;
  /** 是否登录校验，true不进行校验（访客） */
  requiresAuth?: boolean;
  layout?: React.ReactElement | any;
  component?: React.ReactElement | LazyExoticComponent<any> | any;
  routes?: MenuBase[];
}
const routes: MenuBase[] = [
  // 菜单相关路由
  {
    path: '/',
    name: '首页',
    exact: true,
    layout: BasicLayout,
    component: lazy(() => import('@/pages/home'))
  },
  {
    path: '/exchangeSuccess',
    name: '兑换成功',
    exact: true,
    layout: BasicLayout,
    component: lazy(() => import('@/pages/success'))
  },
  {
    path: '/paySuccess',
    name: '兑换成功',
    exact: true,
    layout: BasicLayout,
    component: lazy(() => import('@/pages/paySuccess'))
  },
  {
    path: '/registry',
    name: '账号注册',
    exact: true,
    layout: RegistryLayout,
    component: lazy(() => import('@/pages/registry'))
  },
  {
    path: '/registryResult',
    name: '账号注册',
    exact: true,
    layout: RegistryLayout,
    component: lazy(() => import('@/pages/registryResult'))
  },
  {
    path: '/convertSuc',
    name: '兑换成功',
    exact: true,
    layout: BasicLayout,
    component: lazy(() => import('@/pages/success'))
  },
  {
    path: '/scan',
    name: '扫码',
    exact: true,
    layout: FullLayout,
    component: lazy(() => import('@/pages/scan'))
  },
  {
    path: '/404',
    name: '404',
    exact: true,
    component: lazy(() => import('@/pages/notFound'))
  },
  {
    path: '/demo',
    name: 'demo',
    exact: true,
    component: lazy(() => import('@/pages/demo'))
  },
  ...profileRoutes
];

export default routes;
