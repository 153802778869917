import Cookies from 'js-cookie';
import { constants } from './constants';

const userState: any = {
  registerStatus: false, //是否注册成功，用于自动跳转路由到登录
  token: '', //登录获取的token
  userInfo: {}, //用户详情
  wxData: null, // 微信数据
  openid: null,
  access_token: null,
  regUserInfo: null,
  qrcodeInfo: null,
  entryUrl: '',
  canScan: false,
  showFooter: true,
  mallData: {},
  mallList: [],
  queryMallParams: {
    type: 0,
    pageNum: 1,
    pageSize: 4
  },
  orderData: {},
  orderList: [],
  queryOrderParams: {
    status: 1,
    pageNum: 1,
    pageSize: 200
  },
  hasMore: false,
  refresh: true,
  pointInfo: null
};

const userReducer = (state = userState, action: { type: constants; payload: any }) => {
  switch (action.type) {
    //登录token
    case constants.USER_TOKEN:
      Cookies.set('openid_v2', action.payload.openid);
      Cookies.set('access_token', action.payload.access_token);
      return {
        ...state,
        openid: action.payload.openid,
        access_token: action.payload.access_token,
        wxData: action.payload
      };
    case constants.REG_CONSUMER:
      console.log('REG_CONSUMER:', action.payload);
      const token =
        action.payload && action.payload.reviewStatus === 1 && action.payload.isPass === 1
          ? action.payload.token
          : null;
      token && Cookies.set('token', token);
      return {
        ...state,
        userInfo: action.payload,
        token
      };
    case constants.OPENID:
      // const openid = Cookies.get('openid_v2') || 'oP3mjt_DcakfngIkkxPEPp6AbwjU';
      const openid = Cookies.get('openid_v2');
      return {
        ...state,
        openid: openid
      };
    case constants.QRCODE_SEARCH:
      return {
        ...state,
        qrcodeInfo: action.payload
      };
    case constants.POINT_SEARCH:
      return {
        ...state,
        pointInfo: action.payload
      };
    case constants.ENTRY_URL:
      return {
        ...state,
        entryUrl: action.payload
      };
    case constants.CAN_SCAN:
      return {
        ...state,
        canScan: action.payload
      };
    case constants.SHOW_FOOTER:
      return {
        ...state,
        showFooter: action.payload
      };
    case constants.MALL_LIST:
      console.log(
        'MALL_LIST===',
        action.payload,
        action.payload && action.payload.pageIndex < action.payload.pageCount
      );
      return {
        ...state,
        mallList: action.payload.data && state.mallList.concat(action.payload.data),
        mallData: action.payload,
        hasMore: action.payload && action.payload.pageIndex < action.payload.pageCount
      };
    case constants.QUERY_MALL_PARAMS:
      console.log('QUERY_MALL_PARAMS===', action.payload);
      return {
        ...state,
        queryMallParams: action.payload
      };
    case constants.ORDER_LIST:
      return {
        ...state,
        orderList: action.payload ? action.payload.data : [],
        orderData: action.payload
      };
    case constants.QUERY_ORDER_PARAMS:
      return {
        ...state,
        queryOrderParams: action.payload
      };
    case constants.REFRESH:
      return {
        ...state,
        mallList: []
      };
    default:
      return state;
  }
};

export default userReducer;
