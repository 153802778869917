export enum constants {
  USER_TOKEN = 'user/userToken',
  REG_CONSUMER = 'user/registry',
  QRCODE_SEARCH = 'user/qrcode',
  POINT_SEARCH = 'user/point',
  OPENID = 'user/openid',
  ENTRY_URL = 'common/entryUrl',
  CAN_SCAN = 'common/canScan',
  SHOW_FOOTER = 'common/showFooter',
  MALL_LIST = 'profile/mallList',
  REFRESH = 'refresh',
  ORDER_LIST = 'profile/orderList',
  QUERY_MALL_PARAMS = 'profile/query_mall_params',
  QUERY_ORDER_PARAMS = 'profile/query_order_params'
}
