import React from 'react';
import styles from './footer.module.less';

const Footer = (restProps: any) => {
  return (
    <div style={{ textAlign: 'center', padding: '.8rem 0 .4rem', userSelect: 'none' }} {...restProps}>
      <img className={styles['rw-logo']} src={require('@/assets/images/蓝色logo@2x.png')} alt="" />
    </div>
  );
};

export default Footer;
