import React from 'react';

const Empty = () => {
  return (
    <div
      style={{ padding: '0.3rem 0', marginTop: '15vh' }}
      className="flex-1 flex flex-v flex-align-center flex-pack-center"
    >
      <img
        style={{ width: '2.4rem', height: '1.8rem' }}
        src={require('@/assets/images/nodata.png')}
        alt=""
      />
      <p style={{ color: '#999', marginTop: '0.2rem' }}>暂无数据</p>
    </div>
  );
};

export default Empty;
