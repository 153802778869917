/**
 * 根据1920设置 html fontSize
 */
console.log(11);
function rem() {
  let body = document.documentElement || document.body;
  console.log(body.clientWidth / 7.5);
  let m = body.clientWidth / 7.5;
  body.style.fontSize = (m > 100 ? 100 : m) + 'px';
}

rem();

window.onresize = rem;
