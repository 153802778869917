import Axios from '@/api/apiConfig/axios';
import { buildGET } from '@/utils';

/**
 * @param {string} type
 * @param {string}
 *
 * @return {Object} 返回值描述
 */
export const mallList = async (req: any) => {
  let url = buildGET(`/mall/front/list`, req);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 商品购买
export const payMall = async (req: any) => {
  try {
    return await Axios.request({
      url: '/mall/front/pay',
      data: req,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

/**
 *登录
 *
 * @param {string}
 * @param {string}
 *
 * @return {Object} 返回值描述
 */
export const convertStatus = async (req: any) => {
  let url = buildGET(`/mall/front/convert/status`, req);
  try {
    return await Axios.request({ url, method: 'GET' });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const convertMall = async (req: any) => {
  try {
    return await Axios.request({
      url: `/mall/front/convert`,
      data: req,
      method: 'PUT'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// goods/front/attributes/info
// id  attributes
export const attributesInfo = async (req: any) => {
  const url = buildGET(`/goods/front/attributes/info`, req);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// 兑换现金券
export const convertCash = async (req: any) => {
  try {
    return await Axios.request({
      url: '/cash/front/convert',
      data: req,
      method: 'PUT'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// cash/front/list
export const cashList = async (req: any) => {
  const url = buildGET(`/cash/front/list`, req);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// integral / front / list;
export const integralList = async (req: any) => {
  const url = buildGET(`/integral/front/list`, req);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// address / front / new 查询最近一次填写的地址();
export const addressNew = async () => {
  try {
    return await Axios.request({
      url: '/address/front/new',
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// order/front/list 获取订单列表（支持条件搜索）
export const orderList = async (req: any) => {
  const url = buildGET(`/order/front/list`, req);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// order/front/confirm; 确认收货;
export const confirmOrder = async (req: any) => {
  try {
    return await Axios.request({
      url: '/order/front/confirm',
      data: req,
      method: 'PUT'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// goods/front/attributes/info
// draw/admin/{ id }; 查询中奖id
export const postDraw = async (req: any) => {
  try {
    return await Axios.request({
      url: '/draw/front/request',
      data: req,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const getDraw = async () => {
  try {
    return await Axios.request({
      url: '/draw/front/request',
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const getDrawList = async (req: any) => {
  const url = buildGET(`/draw/front/award`, req);
  try {
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const newsList = async () => {
  try {
    return await Axios.request({
      url: '/news/admin',
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const advertShow = async () => {
  try {
    return await Axios.request({
      url: '/advert/front/show',
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const advertCount = async (id: any) => {
  try {
    return await Axios.request({
      url: `/advert/front/count/${id}`,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const PostWithdrawal = async (params: any) => {
  try {
    return await Axios.request({
      url: `/balance/front/withdrawal`,
      data: params,
      method: 'POST'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// balance/front/list
export const balanceList = async (params: any) => {
  try {
    const url = buildGET(`/balance/front/list`, params);
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//balance/front/withdrawal/list
export const withDrawalList = async (params: any) => {
  try {
    const url = buildGET(`/balance/front/withdrawal/list`, params);
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
//balance/front/withdrawal/result
export const withDrawalResult = async (params: any) => {
  try {
    const url = buildGET(`/balance/front/withdrawal/result`, params);
    return await Axios.request({
      url,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

//balance/front/withdrawal/result
export const getAdminSet = async () => {
  try {
    return await Axios.request({
      url: '/set/admin',
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
