import React from 'react';
import Demo from '../demo';
const NotFound = () => {
  return (
    <div>
      <Demo />
    </div>
  );
};

export default NotFound;
