import React, { FC, useCallback, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import routes from '@/routes/config';
import renderRoutes from '@/routes/index';
import zhCN from 'antd/es/locale/zh_CN';
import { useDispatch } from 'react-redux';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { fetchLoginAction } from '@/store/user/actions';
import moment from 'moment';
import Cookies from 'js-cookie';
import { queryString } from '@/utils/index';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const App: FC = () => {
  const { convertCode, points } = queryString();

  useEffect(() => {
    convertCode && Cookies.set('convertCode', convertCode);
    points && Cookies.set('points', points);
  }, [convertCode, points]);

  const confirmCallback = () => {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'common/entryUrl',
      payload: window.location.href
    });
  }, [dispatch]);

  useAuthInfo(dispatch);

  const getConfirmation: any = useCallback((message: any, callback: any) => {
    callback();
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter getUserConfirmation={getConfirmation(1, confirmCallback)}>
        {renderRoutes(routes)}
      </BrowserRouter>
    </ConfigProvider>
  );
};

/*
  微信授权检测，以及用户相关数据绑定
*/
function useAuthInfo(dispatch: any) {
  console.log(process.env.REACT_APP_ENV);
  useEffect(() => {
    // 测试环境openId 'oP3mjt_DcakfngIkkxPEPp6AbwjU'
    // const openId = Cookies.get('openid_v2') || 'oP3mjt_DcakfngIkkxPEPp6AbwjU';
    const openId = Cookies.get('openid_v2');
    const search = queryString();
    if (!openId) {
      /* 本地没有wx用户信息，则说明用户登录失败 或者 过期 */
      if (search.code) {
        /* code 存在 说明当前正在发起授权 */
        dispatch(fetchLoginAction({ code: search.code }));
      } else {
        // openid = oOksDs_QTTUnJk6wwvofGrCchRfk
        /* 如果不存在，则发起授权 */
        const redirectUriAppId =
          process.env.REACT_APP_ENV === 'production'
            ? 'wxb3ce844c602c3b05' // (营门) 'wx1f8d3c30ced7551d'(农夫)
            : process.env.REACT_APP_ENV === 'development'
            ? 'wxb3ce844c602c3b05'
            : 'wxb3ce844c602c3b05'; // caipeiyi840测试微信 wx0bfa8d6823218ff6
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${redirectUriAppId}&redirect_uri=${encodeURIComponent(
          window.location.href
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      }
    } else {
      // 存在openid, 存入store
      dispatch({ type: 'user/openid' });
    }
  }, [dispatch]);
}

export default withRouter(App);
